export const useDeviceDetection = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    
    // iOS detection
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isIPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    
    // Android detection
    const isAndroid = /android/.test(userAgent);
    
    // Tablet detection
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    
    // General mobile detection
    const isMobile = /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/.test(userAgent);
    
    // Determine device type and details
    let type = 'Desktop';
    let details = '';
  
    if (isIOS || isIPadOS) {
      type = 'iOS';
      details = isTablet ? 'iPad' : 'iPhone';
    } else if (isAndroid) {
      type = 'Android';
      details = isTablet ? 'Android Tablet' : 'Android Phone';
    } else if (isMobile) {
      type = 'Other Mobile';
      details = 'Other Mobile Device';
    }
  
    return {
      type,
      details,
      isIOS: isIOS || isIPadOS,
      isAndroid,
      isTablet,
      isMobile,
      isDesktop: !isMobile && !isTablet,
      // Additional useful device checks
      isSafari: /safari/.test(userAgent) && !/chrome/.test(userAgent),
      isChrome: /chrome/.test(userAgent),
      isFirefox: /firefox/.test(userAgent),
      isEdge: /edg/.test(userAgent),
      // Screen size helpers
      isSmallScreen: window.innerWidth < 768,
      isMediumScreen: window.innerWidth >= 768 && window.innerWidth < 1024,
      isLargeScreen: window.innerWidth >= 1024
    };
  };