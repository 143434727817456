
import './Resume.scss';
import { resumeContent } from '../../assets/theresume.js';

//TEMPLATE: Need to find a better way to script this :(

const Resume = () => {

    return (
        <div className="resume-container">
            <div dangerouslySetInnerHTML={{__html: resumeContent }} />
        </div>
    )

}

export default Resume;