import React, { useState, useRef, useEffect } from 'react';
import './buddies.scss';
import BuddyTopBar from './buddytopbar/buddytopbar';
import { Direction } from './resizer/Constants';
import Resizer from './resizer/Resizer';
import Resume from '../../content/resume/Resume';
import Terminal from '../Terminal/Terminal';

const Buddies = (props) => {

    const panelRef = useRef(null);
    const buddyContentRef = useRef(null);
    const [extraClasses, setExtraClasses] = useState("");
    const [isNotMax, setIsNotMax] = useState(true);
    const [oldHeight, setOldHeight] = useState(0);
    const [oldWidth, setOldWidth] = useState(0);
    const [oldX, setOldX] = useState(0);
    const [oldY, setOldY] = useState(0);

    const handleDrag = (movementX, movementY) => {
        
        if(isNotMax) {
            const panel = panelRef.current;
            if(!panel) return;

            const { x, y } = panel.getBoundingClientRect();

            panel.style.left = `${x + movementX}px`;
            panel.style.top = `${y + movementY}px`;
        }
    };

    const handleResize = (direction, movementX, movementY) => {
        const panel = panelRef.current;
        if(!panel) return;

        const { width, height, x, y } = panel.getBoundingClientRect();

        const resizeTop = () => {
            panel.style.height = `${height - movementY}px`;
            panel.style.top = `${y + movementY}px`;
        };

        const resizeRight = () => {
            panel.style.width = `${width + movementX}px`;
        }

        const resizeBottom = () => {
            panel.style.height = `${height + movementY}px`;
        }

        const resizeLeft = () => {
            panel.style.width = `${width - movementX}px`;
            panel.style.left = `${x + movementX}px`;
        };

        switch(direction) {
            case Direction.TopLeft:
                resizeTop();
                resizeLeft();
                break;
            case Direction.Top:
                resizeTop();
                break;
            case Direction.TopRight:
                resizeTop();
                resizeRight();
                break;
            case Direction.Right:
                resizeRight();
                break;
            case Direction.BottomRight:
                resizeBottom();
                resizeRight();
                break;
            case Direction.Bottom:
                resizeBottom();
                break;
            case Direction.BottomLeft:
                resizeLeft();
                resizeBottom();
                break;
            case Direction.Left:
                resizeLeft();
                break;
            default:
                break;
            
        };

    };

    const [zIndexVar, setZIndexVar] = useState(props?.zIndex);

    useEffect(() => {
        setZIndexVar(props?.zIndexVar);
    }, [props?.zIndex]);

    const maxBuddyEvent = () => {
        if(isNotMax) {
            let panel = panelRef.current;

            //SAVE OLD VALUES
            const { width, height, x, y } = panel.getBoundingClientRect();
            setOldWidth(width);
            setOldHeight(height);
            setOldX(x);
            setOldY(y);

            //SET FULLSCREEN VALUES
            panel.style.width = 'calc(100% - 3vw)';
            panel.style.height = 'calc(calc(100% - 3vh) - 60px)';
            panel.style.left = `0px`;
            panel.style.top = `0px`;
            panel.style.marginLeft ='1.5vw';
            panel.style.marginTop = 'calc(1.5vh + 30px)';

            setIsNotMax(false);
        }
        
    } 

    const normalBuddyEvent = () => {
        let panel = panelRef.current;

        const subtractionTop = 30;
        const viewportHeight = window.innerHeight * 0.015;
        const result = oldY - subtractionTop - viewportHeight;
        const roundedTop = Math.ceil(result);

        //RESET TO OLD WINDOW VALUES
        panel.style.marginLeft = "0"//'auto';
        panel.style.marginRight = "0"//'auto';
        panel.style.marginTop = "0";
        panel.style.left = `${oldX}px`;
        panel.style.top = `${oldY}px`;//`Math.ceil(calc(calc(${oldY}px - 30px) - 1.5vh))`; //IDK WHY IT DRIFTS DOWN :(
        panel.style.width = `${oldWidth}px`;
        panel.style.height = `${oldHeight}px`;
        setIsNotMax(true);
    } 

    //TODO: need conditional flag IF buddy is content or application!

    //TODO: This is just until I have a more elegant solution, which i will of course
    //implement very soon :^)
    
    let buddyContent = <Resume />
    let buddyContentOverflow = true;

    if(props?.content === "resume") {
        buddyContent = <Resume />;
    }
    else if(props?.content === "terminal") {
        buddyContent = <Terminal />;
        buddyContentOverflow = false;
    }

    const cleanupEvent = () => {
        
    }


    return (
        <div className={"buddy-outer"} ref={panelRef} style={{zIndex: props?.zIndex}}//{{zIndex: props?.zIndex}}
        onDoubleClick={() => props?.updateBuddyZIndex(props?.name)}// onClick={() => props?.updateBuddyZIndex(props?.name)}
        >
            <Resizer onResize={handleResize} />
            <BuddyTopBar onDrag={handleDrag} closeEvent={props.closeBuddy} cleanupEvent={() => cleanupEvent} title={props?.title}
                normalBuddyEvent={() => normalBuddyEvent()} maxBuddyEvent={() => maxBuddyEvent()} name={props?.name}/>
            <div className="buddy-content" ref={buddyContentRef} style={{ paddingBottom: buddyContentOverflow ? '0' : '20px' }}>
                {buddyContent}
            </div>
        </div>
    )

}

export default Buddies;