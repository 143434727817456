import Time from '../time/Time';
import './topMenu.scss';

const TopMenu = () => {


    return (
        <div className="topMenu-container">
            <div className='topMenu-leftContent'>
            team7\guest
            </div>
            <div className='topMenu-rightContent-topBar'>
                <Time />
            </div>
        </div>
    )
}

export default TopMenu;