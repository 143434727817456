import React, { useEffect, useState } from 'react';
import { ReactTerminal } from 'react-terminal';

// import { FileSystem, FileSystemItem } from './FileSystem';

//TODO clean up the file system
//TODO file changing doesn't work. Probably need to connect
//with an outside filesystem :(((

let fileSystem = {
    type: 'folder',
    children: {
      documents: {
        type: 'folder',
        children: {
          'resume.pdf': {
            type: 'pdf',
            url: 'https://whompmacdon.b-cdn.net/Pmacdonald%20resume.pdf',
          },
        },
      },
      downloads: {
        type: 'folder',
        children: {
          'secret.txt': {
            type: 'text',
            content: 'SaHR0cHM6Ly90ZWFtNy50ZWNoL2plbnNlbi9pbmRleDIuaHRtbA==',
          },
          'gato.png': {
            type: 'download',
            url: 'https://peterTheYeeter.b-cdn.net/other/gato.png',
          },
        },
      },
      videos: {
        type: 'folder',
        children: {
          'success.mp4': {
            type: 'video',
            url: 'https://www.youtube.com/shorts/1xMvibI5i04',
          },
        },
      },
      music: {
        type: 'folder',
        children: {
          'crimbus.ogg': {
            type: 'audio',
            url: 'https://petertheyeeter.b-cdn.net/songs/all%20i%20want%20for%20cmas%20v1.ogg',
          },
          'pombeiv1.ogg': {
            type: 'audio',
            url: 'https://peterTheYeeter.b-cdn.net/songs/pompei%20v1.ogg',
          },
          'ramblings.ogg': {
            type: 'audio',
            url: 'https://peterTheYeeter.b-cdn.net/songs/ramblings%20v1.ogg',
          },
          'cmagrandom.ogg': {
            type: 'audio',
            url: 'https://peterTheYeeter.b-cdn.net/songs/random%20v1%20cmajor.ogg',
          },
        },
      },
    },
  };

const Terminal = () => {


    const [path, setPath] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editingFile, setEditingFile] = useState(null);
    const [newFileName, setNewFileName] = useState('');

    const handleCreateNewFile = (e) => {
        e.preventDefault();
    
        if (!newFileName) {
          alert('Please provide a file name');
          return;
        }
    
        const currentFolder = getCurrentFolder();
        if (currentFolder.children[newFileName]) {
          alert(`File already exists: ${newFileName}`);
          return;
        }
    
        currentFolder.children[newFileName] = {
          type: 'text',
          content: '',
        };
    
        setIsEditing(true);
        setEditingFile({ fileName: newFileName, type: 'text', content: '' });
    };

    const getCurrentFolder = () => {
        return path.reduce((folder, folderName) => folder.children[folderName], fileSystem);
    };

    const handleSave = () => {
        if (!editingFile) {
          alert('No file is being edited.');
          return;
        }
      
        updateFileContent([...path, editingFile.fileName], editingFile.content);
        setIsEditing(false);
        setEditingFile(null);
        alert(`Saved file: ${editingFile.fileName}`);
    };

    const handleEditorKeyBinding = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
          event.preventDefault();
          handleSave();
        }
    };

    const updateFileContent = (filePath, newContent) => {
        const folderPath = filePath.slice(0, -1);
        const fileName = filePath[filePath.length - 1];
    
        let folder = folderPath.reduce(
          (currentFolder, folderName) => currentFolder.children[folderName],
          fileSystem
        );
    
        if (folder && folder.children[fileName]) {
          folder.children[fileName].content = newContent;
        }
      };

    const commands = {
        help: (whatever) => {

        },
        edit: (fileName) => {
            const currentFolder = getCurrentFolder();
            const file = currentFolder.children[fileName];
            if (!file) {
              return `File not found: ${fileName}`;
            }
      
            if (file.type === 'text') {
              setIsEditing(true);
              setEditingFile({ ...file, fileName });
            } else {
              return `Cannot edit ${fileName}`;
            }
          },
      
          save: () => {
            if (!editingFile) {
              return 'No file is being edited.';
            }
          
            updateFileContent([...path, editingFile.fileName], editingFile.content);
            setIsEditing(false);
            setEditingFile(null);
          
            return `Saved file: ${editingFile.fileName}`;
          },

        
          touch: (fileName) => {
            if (!fileName) {
              return 'Please provide a file name';
            }
      
            const currentFolder = getCurrentFolder();
            if (currentFolder.children[fileName]) {
              return `File already exists: ${fileName}`;
            }
      
            currentFolder.children[fileName] = {
              type: 'text',
              content: '',
            };
      
            return `Created new file: ${fileName}`;
          },

        pwd: () => {
        return '/' + path.join('/');
        },
        ls: () => {
        const currentFolder = getCurrentFolder();
        return Object.keys(currentFolder.children).join(', ');
        },
        cat: (fileName) => {
            const currentFolder = getCurrentFolder();
            const file = currentFolder.children[fileName];
            if (!file) {
              return `File not found: ${fileName}`;
            }
          
            if (file.type === 'text') {
              return file.content;
            } else if (
              file.type === 'image' ||
              file.type === 'pdf' ||
              file.type === 'download' ||
              file.type === 'docx' ||
              file.type === 'audio' ||
              file.type === 'video'
            ) {
              window.open(file.url, '_blank');
              return `Opening ${fileName} in a new tab...`;
            } else {
              return `Cannot open ${fileName}`;
            }
        },
        cd: (folderName) => {
            if (!folderName || folderName === '.') {
                return;
            }

            if (folderName === '..') {
                if (path.length > 0) {
                setPath(path.slice(0, -1));
                }
                return;
            }

            const currentFolder = getCurrentFolder();
            const folder = currentFolder.children[folderName];
            if (!folder || folder.type !== 'folder') {
                return `Folder not found: ${folderName}`;
            }

            setPath([...path, folderName]);
        },
        new: (fileName) => {
            if (!fileName) {
              return 'Please provide a file name';
            }
          
            const currentFolder = getCurrentFolder();
            if (currentFolder.children[fileName]) {
              return `File already exists: ${fileName}`;
            }
          
            setIsEditing(true);
            setEditingFile({ fileName, type: 'text', content: '' });
          },
    };

    const [currentFolder, setCurrentFolder] = useState(fileSystem.root);

    const [theme, setTheme] = useState("dark");

    const [currentPath, setCurrentPath] = useState(['']);
    
    const [currentPrompt, setCurrentPrompt] = useState(">>>");

    const welcomeMessage = (
        <><h3>Welcome to NekoSora 1.1.69 LTS</h3><p>
            <br />
            * Documentation: ls, cd to navigate/explore the folder structure. cat FILENAME to open a file.
            <br />
            * Management: TBD
            <br />
            * Support: TODO
            <br />
            <br />
            THERE ARE NO UPDATES ARRGH ☠️
            <br />
            <br />
            This message is always shown because why not, to disable it send me an angry email:
            <br />
            <br />
            Enter "help" for help 
        </p></>
    );


    return (
        <>
            {
            (
                <ReactTerminal welcomeMessage={welcomeMessage}
                    showControlBar={false}
                    theme={theme}
                    commands={commands}
                    prompt={currentPrompt}
                />
            )
        }
        </>
        
    )
}

export default Terminal;