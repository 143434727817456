import { useEffect, useState } from "react";
import './buddytopbar.scss';
import { useDeviceDetection } from "../../utils/utils";

const BuddyTopBar = (props) => {

    const [mouseDown, setMouseDown] = useState(false);
    const [touchStart, setTouchStart] = useState(false);
    const device = useDeviceDetection;

    useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);

        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.addEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            // console.log(e.movementX)
            // console.log(e.movementY)
            // console.log(e);
            props.onDrag(e.movementX, e.movementY);
        };

        if(mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
        console.log('triggered');
    }, [mouseDown, props.onDrag]);

    const handleMouseDown = (e) => {
        // Only allow dragging if not on mobile and not clicking a control button
        if (!device.isMobile && !e.target.closest('.buddy-topBarControlBox')) {
            setMouseDown(true);
        }
    };

    const handleTouchEnd = ()  => {
        setTouchStart(false);
        window.removeEventListener("touchmove", handleTouchMoveLogic);
    }

    const handleTouchMoveLogic = (e) => {
        // Only process touch moves if not on mobile
        if (!device.isMobile) {
            const touch = e.touches[0];
            if (previousTouch) {
                e.movementX = touch.pageX - previousTouch.pageX;
                e.movementY = touch.pageY - previousTouch.pageY;
                props.onDrag(e.movementX, e.movementY);
            };
            previousTouch = touch;
        }
    }

    const handleTouchStart = (e) => {
        // Only allow touch dragging if not on mobile and not touching a control button
        if (!device.isMobile && !e.target.closest('.buddy-topBarControlBox')) {
            window.addEventListener("touchmove", handleTouchMoveLogic);
            return () => {
                window.removeEventListener("touchmove", handleTouchMoveLogic);
            }
        }
    }

    var previousTouch;
    
    //SIZING LOGIC
    

    return (
        <div className="buddy-topBar" 
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            >
            <div className="buddy-topBarContent">
                {props?.title ? props?.title : "YEAAAHHH????"}
            </div>
            <div className="buddy-topBarControls">
                <div className="buddy-topBarControlBox" onClick={() => props?.normalBuddyEvent()}>
                    ⧉
                </div>
                <div className="buddy-topBarControlBox" onClick={() => props?.maxBuddyEvent()}>
                    ☐
                </div>
                <div className="buddy-topBarControlBox" onClick={() => props?.closeEvent(props?.name)}>
                    X
                </div>
            </div>
        </div>
    )
}

export default BuddyTopBar;