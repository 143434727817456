export const resumeContent = `
    <div>
    <h1>
        Peter MacDonald
    </h1>
    <p>
        peter@team7.tech
    </p>
    <hr />
    <h2>Career Objective</h2>
    <p>
        Working towards a career in Fullstack Development<br />
        Currently working as a Software Engineer
    </p>
    <br />
    <h2>Professional Experience</h2>
    <br />
    <h3>Software Engineer, May 2021 - Present</h3>
    <p>General Motors, Austin, TX</p>
    <ul>
        <li>Develop UI/UX solutions using React along with best frontend web development practices. Introduced and adopted a
            new unit testing framework for the team.</li>
        <li>Generated backend solutions using the Java based HCL ecommerce system and a complex tech stack, assembled a new
            system to monitor and report on backend errors.</li>
        <li>Assisted in coaching of new employees for both frontend and backend roles, facilitated new documentation for
            developments in the team tech stack.</li>
    </ul>
    <h3>Jr .NET Developer, Sep 2019 - May 2021</h3>
    <p>Interstate Van Lines, Springfield, VA</p>
    <ul>
        <li>Maintain and develop applications in a .NET environment including Windows Services, console applications, WPF,
            Windows Forms, Xamarin, .NET core, and ASP.NET. Apply and develop using third party UI controls as needed.
        </li>
        <li>Address and resolve tier three level application tickets.
        </li>
        <li>Gather requirements, plan projects requiring multiple programmers, and consolidate user feedback.</li>
        <li>Manage and develop SSRS SQL systems including reviewing new reports, configuring scheduling, and identifying and
            fixing reporting errors.</li>
    </ul>
    <h3>PC Technician (Help Desk), Aug 2017 - Dec 2019</h3>
    <p>Interstate Van Lines, Springfield, VA</p>
    <br />
    <h2>Education</h2>
    <br />
    <h3>George Mason University</h3>
    <p>Fairfax, VA</p>
    <p><i>Bachelor of Science in Information Technology, Concentration in Database and Programming, May 2021</i></p>
    <br />
    <h2>Additional Skills</h2>
    <ul>
        <li>Proficient with Microsoft Office, Google G-Suite, PAN-OS, and VMware.</li>
        <li>Knowledgeable using SQL, C#, JS, and HTML. Some experience with Swift, Python, and Go.</li>
        <li>Familiarity with Amazon AWS systems including EC2, S3, Cloud9, Lambda, and VPC.</li>
        <li>Boy Scouts of America Eagle Scout, going on to receive the Bronze, Gold & Silver Palm.</li>
    </ul>
    <h3><a target="_blank" href="https://whompmacdon.b-cdn.net/Pmacdonald%20resume.pdf">Link to Resume PDF</a></h3>
</div>
`
